import Web3 from "web3";
import * as Sentry from "@sentry/react";
const contractABI = require("../contracts/Flowtys.json");
let contractAddress = "0x52607cb9c342821ea41ad265B9Bb6a23BEa49468";

export async function getTokensData(address) {
  try {
    const web3Provider = new Web3(
      `wss://mainnet.infura.io/ws/v3/${process.env.REACT_APP_INFURA_APP_ID}`
    );
    const contract = new web3Provider.eth.Contract(
      contractABI.abi,
      contractAddress
    );
    const balance = await contract.methods.balanceOf(address).call();
    if (balance === 0) {
      return [];
    }
    const currentBlock = await web3Provider.eth.getBlockNumber();
    const tokensData = await Promise.all(
      Array.from({ length: balance }, (_, i) => i).map(async (index) => {
        const tokenId = await contract.methods
          .tokenOfOwnerByIndex(address, index)
          .call();
        const startingBlock = await contract.methods
          .getAgeStaringBlock(tokenId)
          .call();
        const ageInDays = getAgeInDays(startingBlock, currentBlock);
        return {
          tokenId,
          ageInDays,
        };
      })
    );
    return tokensData;
  } catch (err) {
    Sentry.captureException(err);
  }
  return [];
}

/**
 * Returns the time difference between the current ethereum block and the token starting block in days (using a 15s average)
 * @param {uint} tokenStartingBlock The block number when the token was mint or transacted for the last time, this is obtained by executing getAgeStaringBlock
 * @param {*} currentBlock Current Ethereum block, this can be obtained directly from web3 library. i.e. web3Provider.eth.getBlockNumber()
 */
function getAgeInDays(tokenStartingBlock, currentBlock) {
  // Block time average in seconds
  // this is the value used by the ethereum Flowtys contract to calculate when to increase the age treat
  // See more here: https://etherscan.io/address/0x52607cb9c342821ea41ad265B9Bb6a23BEa49468#code
  const BLOCK_TIME_AVG = 15;
  if (!currentBlock || !tokenStartingBlock) {
    console.error("currentBlock or tokenStartingBlock was empty");
    return 0;
  }
  if (tokenStartingBlock >= currentBlock) {
    return 0;
  }
  return parseInt(
    ((currentBlock - tokenStartingBlock) * BLOCK_TIME_AVG) / (3600 * 24)
  );
}
