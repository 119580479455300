import "./App.css";
import metamask from "./assets/metamask.svg";
import walletConnect from "./assets/walletc.svg";
import coinbaseWallet from "./assets/coibaseWallet.webp";
import Web3 from "web3";
import WalletLink from "walletlink";
import detectEthereumProvider from "@metamask/detect-provider";
import { getTokensData } from "./model/Blockchain";
import { useEffect, useState, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Modal,
} from "react-bootstrap";
import WalletConnectProvider from "@walletconnect/web3-provider";
import * as Sentry from "@sentry/react";

const fetchRetry = require("fetch-retry")(fetch);

function App() {
  const [flowtys, setFlowtys] = useState([]);
  const [currentAccount, setCurrentAccount] = useState("");
  const [discordUser, setDiscordUser] = useState();
  const [isLogged, setIsLogged] = useState(false);
  const [hasFlowty, setHasFlowty] = useState(false);
  const [modalConnectShow, setModalConnectShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  let isMobile = width <= 768;
  const [currentProvider, setProvider] = useState();
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const type = queryParams.get("type");

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const SignIn = useCallback(async () => {
    setModalConnectShow(true);
  }, []);

  const getFlowtys = async (provider, address) => {
    setLoading(true);
    const params = {
      method: "GET",
      retries: 3,
      crossDomain: true,
      retryDelay: 200,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const tokensData = await getTokensData(address);
      if (type) {
        const allMeta = await Promise.all(
          tokensData.map(async (tokenData) => {
            const result = await fetchRetry(
              `https://club.flowtys.com/metadata/${tokenData.tokenId}/0`,
              params
            );
            const metadata = await result.json();
            return {
              ...tokenData,
              ...metadata,
            };
          })
        );
        setFlowtys(type ? allMeta.filter(el => el.attributes.filter(at => at.trait_type === 'Shape' && at.value.toLowerCase() === type ).length > 0 ) : allMeta);
      } else if (tokensData && tokensData.length) {
        setHasFlowty(true)
      }
      console.log(discordUser);
    } catch (err) {
      Sentry.captureException(err);
    }
    setLoading(false);
  };

  const connectEth = async (provider, isMetamask) => {
    try {
      const web3 = new Web3(provider);
      if (!provider) {
        
      } else {
        const address = await ConnectWallet(web3, isMetamask);
        if (!!address) {
          await getFlowtys(provider, address);
          window.ethereum.on("accountsChanged", handleAccountsChanged);
        }
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const connectMetamask = async () => {
    setModalConnectShow(false);
    const provider = await detectEthereumProvider();
    setProvider(provider);
    await connectEth(provider, true);
  };

  const connectWalletConnect = async () => {
    setModalConnectShow(false);
    const provider = new WalletConnectProvider({
      infuraId: process.env.REACT_APP_INFURA_APP_ID,
    });
    try {
      await provider.enable();
      setProvider(provider);
      await connectEth(provider, false);
    } catch (e) {
      console.error(e);
    }
  };

  const connectWalletLink = async () => {
    setModalConnectShow(false);
    const walletLink = new WalletLink({
      appName: "Flowtys Studio",
      appLogoUrl: "/favicon.ico",
      darkMode: true,
    });

    // Initialize a Web3 Provider object
    const provider = walletLink.makeWeb3Provider(
      `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_APP_ID}`,
      1
    );

    try {
      setProvider(provider);
      const accounts = await provider.request({
        method: "eth_requestAccounts",
      });
      setIsLogged(true);
      setCurrentAccount(accounts[0]);
      await getFlowtys(provider, accounts[0]);
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    } catch (e) {
      console.error(e);
    }
  };

  const ConnectWallet = async (web3, isMetamask) => {
    try {
      const accounts = isMetamask
        ? await window.ethereum.request({ method: "eth_requestAccounts" })
        : await web3.eth.getAccounts();
      setIsLogged(true);
      setCurrentAccount(accounts[0]);
      return accounts[0];
    } catch (err) {
      if (err.code === 4001) {
        // EIP-1193 userRejectedRequest error
        // If this happens, the user rejected the connection request.
        console.info("Please connect to MetaMask.");
        
      } else if (err.code === -32002) {
        console.info("Please unlock MetaMask.");
        
      } else {
        console.error(err);
      }
    }
  };

  const handleAccountsChanged = async (accounts) => {
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
    } else if (accounts[0] !== currentAccount) {
      setCurrentAccount(accounts[0]);
      await getFlowtys(currentProvider, accounts[0]);
    }
  };

  const verifyOwner = async () => {
    const web3 = new Web3(currentProvider);
    const mes = await web3.eth.personal.sign(`I'm verified ${type} owner!`, currentAccount);
    window.location.href = `https://flowtys.api.stdlib.com/verifybot-${type}@dev/${type}-verify/?token=${token}&mes=${mes}`;
  };

  const verifyOwnerGeneric = async () => {
    const web3 = new Web3(currentProvider);
    const mes = await web3.eth.personal.sign(`I'm verified Flowty owner!`, currentAccount);
    window.location.href = `https://flowtys.api.stdlib.com/verifybot@dev/verify/?&mes=${mes}&user=${discordUser.id}`;
  };

  const getSomeFlowty = () => {
    if (type === 'fluffy') {
      return 'https://opensea.io/collection/flowtys?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Shape&search[stringTraits][0][values][0]=Fluffy';
    }
    if (type === 'lumpy') {
      return 'https://opensea.io/collection/flowtys?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Shape&search[stringTraits][0][values][0]=Lumpy';
    }
    if (type === 'star') {
      return 'https://opensea.io/collection/flowtys?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Shape&search[stringTraits][0][values][0]=Star';
    }
    if (type === 'ghost') {
      return 'https://opensea.io/collection/flowtys?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Shape&search[stringTraits][0][values][0]=Ghost';
    }
    if (type === 'sketched') {
      return 'https://opensea.io/collection/flowtys?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Shape&search[stringTraits][0][values][0]=Sketched';
    }
    return 'https://opensea.io/collection/flowtys';
  }

  const discordAvatar = () => {
    return discordUser ? `https://cdn.discordapp.com/avatars/${discordUser.id}/${discordUser.avatar}.png` : '';
  }

  useEffect(() => {
    const fragment = new URLSearchParams(window.location.hash.slice(1));
		const [accessToken, tokenType] = [fragment.get('access_token'), fragment.get('token_type')];
    const fetchUsers = () => {
        fetch('https://discord.com/api/users/@me', {
          headers: {
            authorization: `${tokenType} ${accessToken}`,
          },
        })
        .then(result => result.json())
        .then(response => {
          setDiscordUser(response);
        })
        .catch(console.error);
    };

    if (accessToken) {
      fetchUsers();
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function ConectModal(props) {
    return (
      <Modal
        {...props}
        className="align-items-center justify-content-center"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="trusted-modal"
        centered
      >
        <Modal.Body>
          <Row
            className="align-items-center justify-content-center h-100 row-border wallet-connect-hover"
            onClick={connectMetamask}
          >
            <Col className="align-items-center justify-content-center ">
              <Row className="align-items-center justify-content-center mt-3">
                <img
                  alt=""
                  style={{ transition: "none" }}
                  src={metamask}
                  className="metamask-icon"
                />
              </Row>
              <Row className="align-items-center justify-content-center mt-2">
                <div className="wallet-connect-title text-center">MetaMask</div>
              </Row>
              <Row className="align-items-center justify-content-center mb-5">
                <div className="wallet-connect-sub text-center">
                  Connect to your MetaMask Wallet
                </div>
              </Row>
            </Col>
          </Row>
          <Row
            className="align-items-center justify-content-center h-100 wallet-connect-hover row-border"
            onClick={connectWalletConnect}
          >
            <Col className="align-items-center justify-content-center ">
              <Row className="align-items-center justify-content-center mt-3">
                <img
                  alt=""
                  style={{ transition: "none" }}
                  src={walletConnect}
                  className="metamask-icon"
                />
              </Row>
              <Row className="align-items-center justify-content-center mt-2">
                <div className="wallet-connect-title text-center">
                  Wallet Connect
                </div>
              </Row>
              <Row className="align-items-center justify-content-center mb-5">
                <div className="wallet-connect-sub text-center">
                  Scan with WalletConnect to connect
                </div>
              </Row>
            </Col>
          </Row>
          <Row
            className="align-items-center justify-content-center h-100 wallet-connect-hover"
            onClick={connectWalletLink}
          >
            <Col className="align-items-center justify-content-center ">
              <Row className="align-items-center justify-content-center mt-3">
                <img
                  alt=""
                  style={{ transition: "none" }}
                  src={coinbaseWallet}
                  className="metamask-icon"
                />
              </Row>
              <Row className="align-items-center justify-content-center mt-2">
                <div className="wallet-connect-title text-center">
                  Coinbase Wallet
                </div>
              </Row>
              <Row className="align-items-center justify-content-center mb-5">
                <div className="wallet-connect-sub text-center">
                  Connect your browser or mobile app
                </div>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <div>
        <ConectModal
          show={modalConnectShow}
          onHide={() => setModalConnectShow(false)}
        />

        <Container fluid>
        <Row hidden={!discordUser} className="align-items-center justify-content-center main-box">
          <Col className="align-items-center justify-content-center">
            <Row md="auto" className="justify-content-center w-100">
              <div className="text-center  center-div">
                <div className="text-center">
                  <Row className="justify-content-center mb-1">
                    <img alt="" src={discordAvatar()} className="flowtys_woodman" />{" "}
                  </Row>
                  <Row className="justify-content-center">
                    <div className="div-text text-center align-items-center">
                      <div className="text-row">Hey there {discordUser ? discordUser.username : ''}</div>
                      <div className="text-row">ready to verify your Flowtys?</div>
                    </div>
                  </Row>
                </div>
              </div>
            </Row>
            <Row hidden={isLogged} md="auto" className="justify-content-center w-100 mt-5">
                <Button
                  className="main-button main-connect-button"
                  onClick={SignIn}
                >
                  Connect
                </Button>{" "}
              </Row>
            <Row hidden={!hasFlowty} className="align-items-center justify-content-center mb-1 mt-5">
                <Col md="auto">
                  <Button 
                    className="main-action-button "
                    onClick={verifyOwnerGeneric}
                  >
                    Lemme in
                  </Button>{" "}
                </Col>
            </Row>
          </Col>
        </Row>
          <Row hidden={isLogged || discordUser} className="h-100 z-high">
            <Col className="align-items-center justify-content-center h-100  ">
              <Row md="auto" className="justify-content-center w-100 main-box">
                <Button
                  className="main-button main-connect-button"
                  onClick={SignIn}
                >
                  Connect
                </Button>{" "}
              </Row>
            </Col>
          </Row>
          <div hidden={!isLogged}> 
            <Row hidden={!loading} className="mt-5 list-flowtys">
                <div className="pfp-modal-title"> Loading...</div>
              </Row>
            <Row hidden={loading || (discordUser ? hasFlowty : flowtys.length )} className="mt-5 h-100 z-high">
                <Row hidden={flowtys.length} md="auto" className="justify-content-center mb-5">
                  <div className="pfp-modal-title"> Oops, you don't have any {type}.</div>
                </Row>
                <Row hidden={flowtys.length} md="auto" className="justify-content-center">
                  <Button
                    className="main-button main-connect-button"
                    href={getSomeFlowty()}
                  >
                    Get Some!
                  </Button>{" "}
                </Row>
            </Row>
            <Row hidden={!flowtys.length} className="align-items-center justify-content-center mb-1 mt-5">
              <Col md="auto">
                <Button 
                  className="main-action-button "
                  onClick={verifyOwner}
                >
                  Lemme in
                </Button>{" "}
              </Col>
            </Row>
              <Row hidden={!flowtys.length || discordUser} className="mt-5 justify-content-center list-flowtys">
                {flowtys.map((item, index) => {
                    return (
                      <Col
                        md={3}
                        lg={2}
                        key={`col_${index}`}
                        className="justify-content-md-center mr-2 ml-2 mb-5 col-flowtys"
                      >
                        <Row className="justify-content-center align-items-center content">
                          <Image
                            className="flowty-img"
                            src={item.image}
                            alt="loading..."
                            roundedCircle
                            crossOrigin="anonymous"
                          />
                        </Row>
                        <Row className="justify-content-center align-items-center ">
                          <div className="flowty-info">
                            {item.name.replace("Flowty", "")}
                          </div>
                        </Row>
                        <Row className="justify-content-center align-items-center ">
                          <div className="flowty-info">
                            {item.ageInDays} days old
                          </div>
                        </Row>
                      </Col>
                    );
                })}
            </Row>
          </div>
           
        </Container>
      </div>
    </>
  );
}

export default App;
